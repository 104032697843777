import React, { Component, useContext } from 'react';
import { Routes, Route, Outlet, useParams, Navigate } from 'react-router-dom';
import Chat from './Chat/Chat';
import Evaluaciones from './Evaluaciones/Evaluaciones';
import Planificaciones from './Planificaciones/Planificaciones';
import PISA from './PISA/PISA';
import HomeLibro from './HomeLibro/HomeLibro';
import asterSolo from './HomeLibro/MainContent/images/AsterTecSolo.png';
import logoImage from './HomeLibro/MainContent/images/LogoDidactica.png';
import { getBookConfig } from './elDatador';
import { AuthContext } from './AuthContext/AuthContext'; // Importar AuthContext

const MenuWrapper = () => {
  const { id: bookId } = useParams(); // Obtener el id del libro de los parámetros de la URL
  const { user, isAuthenticated, loading } = useContext(AuthContext); // Obtener el usuario y estado de autenticación del contexto

  if (loading) {
    return <div>Cargando...</div>; // Puedes mostrar un spinner de carga aquí
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />; // Redirigir a la página de inicio de sesión si no está autenticado
  }

  return <Menu id={bookId} identificador={user?.id} welcomeText={`¡Bienvenido, ${user?.nombre}!`} />;
};

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookData: null
    };
  }

  async componentDidMount() {
    const { id } = this.props;
    const bookData = await getBookConfig(id);
    this.setState({ bookData });
  }

  render() {
    const { identificador, welcomeText } = this.props;
    const { bookData } = this.state;

    if (!bookData) {
      return <div>Cargando...</div>;
    }

    return (
      <main>
        <Routes>
          <Route path="/" element={
            <HomeLibro
              backgroundImage={bookData.backgroundImage}
              libroTitulo={bookData.libroTitulo}
              logoImage={logoImage}
              asterImage={asterSolo}
              welcomeText={welcomeText}
              chatButtonColor={bookData.chatButtonColor}
              planificacionesButtonColor={bookData.planificacionesButtonColor}
              evaluacionesButtonColor={bookData.evaluacionesButtonColor}
              socialButtonColor={bookData.socialButtonColor}
            />
          } />
          <Route path="chat" element={
            <Chat
              identificador={identificador}
              libro={bookData.libro}
              rama={bookData.rama}
              unidades={bookData.unidadesYExtras}
              modalColor={bookData.modalColor}
              generalBackgroundColor={bookData.generalBackgroundColor}
              checkmarkColor={bookData.checkmarkColor}
              checkmarkBorderColor={bookData.checkmarkBorderColor}
              checkmarkHoverColor={bookData.checkmarkHoverColor}
              checkmarkCheckedColor={bookData.checkmarkCheckedColor}
              scrollbarThumbHoverColor={bookData.scrollbarThumbHoverColor}
              botonAceptarColor={bookData.botonAceptarColor}
              botonAceptarColorHover={bookData.botonAceptarColorHover}
              colorExterior={bookData.colorExterior}
              chatContainerColor={bookData.modalColor}
              chatHeaderColor={bookData.generalBackgroundColor}
              chatFormColor={bookData.generalBackgroundColor}
              userBubbleColor={bookData.userBubbleColor}
              assistantBubbleColor={bookData.assistantBubbleColor}
              sendButtonColor={bookData.sendButtonColor}
              sendButtonHoverColor={bookData.sendButtonHoverColor}
              headerTextColor={bookData.colorTituloChat}
              linkParaHablarConElChat={bookData.linkParaHablarConElChat}
              temasTitulos={bookData.temasTitulos}
              libroExtendido= {bookData.libroExtendido}
            />
          } />
          <Route path="evaluaciones" element={
            <Evaluaciones
              backendUrlCorrecion="https://api-didactica-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/corregirEvaluacion"
              backendUrlGeneracion="https://api-didactica-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/generarEvaluacion"
              identificador={identificador}
              libro={bookData.libro}
              rama={bookData.rama}
              unidades={bookData.unidades}
              modalColor={bookData.modalColor}
              generalBackgroundColor={bookData.generalBackgroundColor}
              checkmarkColor={bookData.checkmarkColor}
              checkmarkBorderColor={bookData.checkmarkBorderColor}
              checkmarkHoverColor={bookData.checkmarkHoverColor}
              checkmarkCheckedColor={bookData.checkmarkCheckedColor}
              scrollbarTrackColor={bookData.scrollbarTrackColor}
              scrollbarThumbColor={bookData.scrollbarThumbColor}
              scrollbarThumbHoverColor={bookData.scrollbarThumbHoverColor}
              botonAceptarColor={bookData.botonAceptarColor}
              botonAceptarColorHover={bookData.botonAceptarColorHover}
              chatContainerColor={bookData.chatContainerColor}
              userBubbleColor={bookData.userBubbleColor}
              assistantBubbleColor={bookData.assistantBubbleColor}
              sendButtonColor={bookData.sendButtonColor}
              sendButtonHoverColor={bookData.sendButtonHoverColor}
              headerTextColor={bookData.headerTextColor}
              fondoDelChat={bookData.fondoDelChat}
              textAreaBorder={bookData.textAreaBorder}
              btndocxColor={bookData.btndocxColor}
              btndocxColorHover={bookData.btndocxColorHover}
              libroExtendido= {bookData.libroExtendido}
            />
          } />
          <Route path="planificaciones" element={
            <Planificaciones
              identificador={identificador}
              libro={bookData.libro}
              rama={bookData.rama}
              unidades={bookData.unidades}
              modalColor={bookData.modalColor}
              generalBackgroundColor={bookData.generalBackgroundColor}
              checkmarkColor={bookData.checkmarkColor}
              checkmarkBorderColor={bookData.checkmarkBorderColor}
              checkmarkHoverColor={bookData.checkmarkHoverColor}
              checkmarkCheckedColor={bookData.checkmarkCheckedColor}
              scrollbarTrackColor={bookData.scrollbarTrackColor}
              scrollbarThumbColor={bookData.scrollbarThumbColor}
              scrollbarThumbHoverColor={bookData.scrollbarThumbHoverColor}
              botonAceptarColor={bookData.botonAceptarColor}
              botonAceptarColorHover={bookData.botonAceptarColorHover}
              chatContainerColor={bookData.chatContainerColor}
              userBubbleColor={bookData.userBubbleColor}
              assistantBubbleColor={bookData.assistantBubbleColor}
              sendButtonColor={bookData.sendButtonColor}
              sendButtonHoverColor={bookData.sendButtonHoverColor}
              headerTextColor={bookData.headerTextColor}
              fondoDelChat={bookData.fondoDelChat}
              textAreaBorder={bookData.textAreaBorder}
              linkParaPlanificaciones={bookData.linkParaPlanificaciones}
              temasTitulos={bookData.temasTitulos}
              btndocxColor={bookData.btndocxColor}
              btndocxColorHover={bookData.btndocxColorHover}
              libroExtendido= {bookData.libroExtendido}
            />
          } />
          <Route path="PISA" element={
            <PISA
              backendUrlCorrecion="https://api-didactica-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/corregirEvaluacion"
              backendUrlGeneracion="https://api-didactica-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/generarEvaluacion"
              identificador={identificador}
              libro={bookData.libro}
              rama={bookData.rama}
              unidades={bookData.unidades}
              modalColor={bookData.modalColor}
              generalBackgroundColor={bookData.generalBackgroundColor}
              checkmarkColor={bookData.checkmarkColor}
              checkmarkBorderColor={bookData.checkmarkBorderColor}
              checkmarkHoverColor={bookData.checkmarkHoverColor}
              checkmarkCheckedColor={bookData.checkmarkCheckedColor}
              scrollbarTrackColor={bookData.scrollbarTrackColor}
              scrollbarThumbColor={bookData.scrollbarThumbColor}
              scrollbarThumbHoverColor={bookData.scrollbarThumbHoverColor}
              botonAceptarColor={bookData.botonAceptarColor}
              botonAceptarColorHover={bookData.botonAceptarColorHover}
              chatContainerColor={bookData.chatContainerColor}
              userBubbleColor={bookData.userBubbleColor}
              assistantBubbleColor={bookData.assistantBubbleColor}
              sendButtonColor={bookData.sendButtonColor}
              sendButtonHoverColor={bookData.sendButtonHoverColor}
              headerTextColor={bookData.headerTextColor}
              fondoDelChat={bookData.fondoDelChat}
              textAreaBorder={bookData.textAreaBorder}
              btndocxColor={bookData.btndocxColor}
              btndocxColorHover={bookData.btndocxColorHover}
              libroExtendido= {bookData.libroExtendido}
            />
          } />
        </Routes>
        <Outlet />
      </main>
    );
  }
}

export default MenuWrapper;
