import React, { Component } from 'react';
import Seccion1 from './Seccion1/S1';
import Overlay from './Overlay/Overlay';
import Volver from './Volver/Volver';
import ReportBugButton from "../Report/ReportBugButton";

class PISA extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOverlayVisible: true,
            responseData: null,
            showInitialMessage: false
        };

        this.handleAccept = this.handleAccept.bind(this);
        this.setResponseData = this.setResponseData.bind(this);
    }

    handleAccept() {
        this.setState({
            isOverlayVisible: false,
            showInitialMessage: true
        });
    }

    setResponseData(data) {
        this.setState({
            responseData: data,
            showInitialMessage: false
        });
    }

    render() {
        const {
            modalColor,
            generalBackgroundColor,
            checkmarkColor,
            checkmarkBorderColor,
            checkmarkHoverColor,
            checkmarkCheckedColor,
            scrollbarTrackColor,
            scrollbarThumbColor,
            scrollbarThumbHoverColor,
            botonAceptarColor,
            botonAceptarColorHover,
            chatContainerColor,
            userBubbleColor,
            assistantBubbleColor,
            sendButtonColor,
            sendButtonHoverColor,
            headerTextColor,
            fondoDelChat,
            textAreaBorder,
            btndocxColor,
            btndocxColorHover,
            unidades
        } = this.props;

        const { isOverlayVisible, responseData, showInitialMessage } = this.state;

        return (
            <div>
                <Volver isVisible={true} titulo = {this.props.libroExtendido}/>
                {isOverlayVisible && (
                    <Overlay
                        backendUrlGeneracion = {this.props.backendUrlGeneracion}
                        identificador = {this.props.identificador}
                        libro =  {this.props.libro}
                        rama = {this.props.rama}
                        setResponseData={this.setResponseData}
                        unidades={unidades}
                        modalColor={modalColor}
                        generalBackgroundColor={generalBackgroundColor}
                        checkmarkColor={checkmarkColor}
                        checkmarkBorderColor={checkmarkBorderColor}
                        checkmarkHoverColor={checkmarkHoverColor}
                        checkmarkCheckedColor={checkmarkCheckedColor}
                        scrollbarTrackColor={scrollbarTrackColor}
                        scrollbarThumbColor={scrollbarThumbColor}
                        scrollbarThumbHoverColor={scrollbarThumbHoverColor}
                        botonAceptarColor={botonAceptarColor}
                        botonAceptarColorHover={botonAceptarColorHover}
                        isVisible={isOverlayVisible}
                        onAccept={this.handleAccept}
                    />
                )}

                <Seccion1
                    backendUrlCorrecion = {this.props.backendUrlCorrecion}
                    identificador = {this.props.identificador}
                    libro =  {this.props.libro}
                    rama = {this.props.rama}
                    responseData={responseData}
                    showInitialMessage={showInitialMessage}
                    chatContainerColor={chatContainerColor}
                    chatHeaderColor={generalBackgroundColor}
                    chatFormColor={generalBackgroundColor}
                    userBubbleColor={userBubbleColor}
                    assistantBubbleColor={assistantBubbleColor}
                    headerTextColor={headerTextColor}
                    sendButtonColor={sendButtonColor}
                    sendButtonHoverColor={sendButtonHoverColor}
                    fondoDelChat={fondoDelChat}
                    textAreaBorder={textAreaBorder}
                    btndocxColor={btndocxColor}
                    btndocxColorHover={btndocxColorHover}
                    libroExtendido = {this.props.libroExtendido}
                />
                <ReportBugButton/>
            </div>
        );
    }
}

export default PISA;
