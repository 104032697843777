import React from 'react';
import styles from './Volver.module.css';
import { Link, useParams } from 'react-router-dom';

const Volver = ({ isVisible, onVolver, titulo }) => {
    const { id } = useParams(); // Obtener el ID de la URL

    if (!isVisible) return null;

    return (
        <div className={styles.volverContainer}>
            <Link to={`/libros/${id}`} className={styles.link}>
                <button onClick={onVolver} className={styles.volverButton}>Volver</button>
            </Link>
            <span className={styles.bienvenidoTexto}>{`¡Bienvenido al ${titulo}! --- Ahora mismo te encuentras en la sección PISA`}</span>
        </div>
    );
};

export default Volver;
